<template>
    <div class="card card-stretch mb-4">
        <div class="card-header border-0">
            <div class="d-flex">
                <!-- if isDraggable -->
                <div v-if="isDraggable" class="lot-drag-handle me-2" style="cursor: grab;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        style="width: 15px; height: 100%; display: block;" class="align-self-center">
                        <path fill-opacity="0.2"
                            d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                </div>
                <div class="d-flex">
                    <h3 class="card-title">
                        <span class="card-label fw-bolder text-dark fs-3 me-0">{{ todo.title }}</span>
                    </h3>
                    <div v-if="assignedToCurrentUser" class="d-flex align-items-center">
                        <h3 class="card-title">
                            <span v-if="todo.project" class="card-label fw-bolder text-dark fs-3">- {{ todo.project.name
                                }}</span>
                            <span v-if="!todo.project" class="card-label fw-bolder text-dark fs-3">- Todo
                                d'agence</span>
                        </h3>
                        <IconButton iconName="box-arrow-up-right"
                            @click="this.$router.push(todo.project ? { name: 'project.todos', params: { id: todo.project.id } } : { name: 'todos' })" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar">
                <ul class="nav nav-pills nav-pills-sm nav-light">
                    <li class="nav-item">
                        <EditButton @click="editTodo"></EditButton>
                    </li>
                    <li class="nav-item">
                        <DeleteButton @click="showDeleteTodo"></DeleteButton>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div v-if="!getItems().length" class="text-center w-25 mx-auto">
                <TodoEmptyState message="Aucune tâche" buttonMessage="Ajouter une tâche"
                    image="/assets/media/svg/empty-states/empty-todo.svg" @add="addTodoItem" />
            </div>
            <draggable :list="getItems()" :disabled="!enabled || !isDraggable" item-key="id" class="list-group mb-8"
                :component-data="{
                    tag: 'div',
                    type: 'transition-group',
                    name: !dragging ? 'flip-list' : null
                }" @start="dragging = true" @end="dragging = false; onDropped()" v-bind="dragOptions" @add="onAdd">
                <template #item="{ element }">
                    <div class="list-group-item" :class="{ 'not-draggable': !enabled || !isDraggable }">
                        <TodoItem :todo-item="element" :todo-id="todo.id" :project-id="projectId"
                            :is-draggable="isDraggable" :assignedToCurrentUser="assignedToCurrentUser" />
                    </div>
                </template>
            </draggable>
            <TodoItemForm class="mb-8" v-if="showTodoItemForm" :projectId="projectId" :todo-id="todo.id"
                :last-order="getLastOrder()" @close="reset"
                :auto-assigned-to-id="assignedToCurrentUser ? currentUser.id : null"
                :assignedToCurrentUser="assignedToCurrentUser" />
            <button v-if="!showTodoItemForm" @click="addTodoItem" type="button" class="btn btn-primary">Ajouter une
                tâche</button>
            <DeleteTodoConfirmModal v-if="showTodoDeleteModal" :todo="todo" :projectId="projectId" @close="reset"
                :assignedToCurrentUser="assignedToCurrentUser" />
            <TodoFormModal v-if="showTodoEditModal" @close="reset" :todo="todo" :projectId="projectId"
                :assignedToCurrentUser="assignedToCurrentUser" />
        </div>
    </div>
</template>

<script>
import DeleteButton from '@/views/_core/components/DeleteButton.vue';
import EditButton from '@/views/_core/components/EditButton.vue';
import IconButton from '@/views/_core/components/IconButton.vue';
import { CURRENT_USER_QUERY } from '@/views/users/data/users_graphql';
import draggable from 'vuedraggable';
import { BULK_UPDATE_TODO_ITEM_ORDER, GET_TODOS } from '../../../data/todos_graphql';
import TodoFormModal from "../../todo-form/TodoFormModal.vue";
import TodoItemForm from "../../todo-form/TodoItemForm.vue";
import DeleteTodoConfirmModal from "./DeleteTodoConfirmModal.vue";
import TodoEmptyState from "./TodoEmptyState.vue";
import TodoItem from "./TodoItem.vue";
export default {
    name: "Todo-List",
    props: {
        todo: {
            type: Object,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        isDraggable: {
            type: Boolean,
            required: false,
            default: true,
        },
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        TodoItem,
        TodoFormModal,
        DeleteTodoConfirmModal,
        TodoItemForm,
        EditButton,
        DeleteButton,
        draggable,
        IconButton,
        TodoEmptyState,
    },
    apollo: {
        currentUser: {
            query: CURRENT_USER_QUERY,
        },
    },
    data() {
        return {
            showTodoItemForm: false,
            showTodoEditModal: false,
            showTodoDeleteModal: false,
            dragging: false,
            enabled: true,
            localItems: [],
            lastStringifiedItems: "",
        };
    },
    methods: {
        reset() {
            this.showTodoItemForm = false;
            this.showTodoEditModal = false;
            this.showTodoDeleteModal = false;
        },
        addTodoItem() {
            this.showTodoItemForm = true;
        },
        editTodo() {
            this.showTodoEditModal = true;
        },
        showDeleteTodo() {
            this.showTodoDeleteModal = true;
        },
        getLastOrder() {
            if (!this.todo.todoItems.length) {
                return 0;
            }
            return this.todo.todoItems[this.todo.todoItems.length - 1].order;
        },
        onDropped() {
            this.$apollo.mutate({
                mutation: BULK_UPDATE_TODO_ITEM_ORDER,
                variables: {
                    todoItems: [...this.localItems.map((todo, index) => (JSON.stringify({
                        id: todo.id,
                        order: index,
                        todoId: this.todo.id,
                    })))],
                },
                refetchQueries: [
                    {
                        query: GET_TODOS,
                        variables: {
                            projectId: this.projectId,
                            assignedToCurrentUser: this.assignedToCurrentUser
                        }
                    },
                ],
            });
        },
        onAdd() {
            // Will save the new order and new todoId
            this.onDropped();
        },
        getItems() {

            // Stringified items without the order and updatedAt fields
            const stringifiedItems = JSON.stringify(this.todo.todoItems.map(item => {
                // eslint-disable-next-line no-unused-vars
                const { order, updatedAt, ...rest } = item;
                return rest;
            }));

            if (this.lastStringifiedItems !== stringifiedItems) {
                this.localItems = [...this.todo.todoItems];
                this.lastStringifiedItems = stringifiedItems;
            }
            return this.localItems
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "todoItems",
                ghostClass: "ghost",
            };
        },
    },
};
</script>